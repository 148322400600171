import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumb from '../../../components/breadcrumb/breadcrumb'
import { environment as ENV } from '../../../deploy/environment'
import Select from './../../../components/select/select'
import UploadFileComponent from './../../../components/upload-file/upload-file'
import {
  checkCustomer,
  getCustomers,
  setCustomer
} from './../../../resources/services'

//Action
import { updateAppAction } from './../../../redux/actions/app'

import { USER_ROLES } from '../../../core/constants/roles'
import { isAlphabetical, isEmail } from '../../../core/utils/validations'
import './detail.css'

class CustomerDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        EMAIL: '',
        PASSWORD: '',
        NAME: '',
        CLINIC_NAME: '',
        TELEFONO: '',
        LOGO: '',
        DNI: '',
        DELIVERY_DAY: '',
        COUNTRY: '',
        CITY: '',
        ADDRESS: '',
        OBSERVACIONES: '',
        DISCOUNT: 0,
        LEGAL_DOCUMENT: 0,
        N_ORDERS: 0,
        STATUS: '1'
      },
      showModal: false,
      error: false,
      legaDocumentsUploadeds: false,
      buttonDisabled: false,
      showEmailExist: false
    }
    this.currentEmail = ''
    this.ukey = props.match.params.ukey
    this.pathFile =
      ENV.rootFiles + '/customer-attachment/' + this.ukey + '/profile/'
    this.deliveryOptions = [
      { value: '8', label: 'Semanal' },
      { value: '9', label: 'Quincenal' },
      { value: '10', label: 'Mensual' }
    ]

    if (this.ukey) {
      getCustomers(this.ukey).then((result) => {
        if (result.response) {
          const data = result.customers[0]
          data.N_ORDERS = data.N_ORDERS ?? 0
          data.PASSWORD = ''
          this.currentEmail = data.EMAIL
          //data.LOGO = data.LOGO && data.LOGO !== '' ? this.pathFile + data.LOGO : data.LOGO;
          this.setState({ data })
        } else {
          toast('No se han podido recuperar los datos.', {
            type: toast.TYPE.ERROR
          })
        }
      })
    } else {
      if (this.props.app.idRol !== '99') {
        this.state.error = true
      }
    }

    this.save = this.save.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.checkUser = this.checkUser.bind(this)
    this.timeout = setTimeout(() => {}, 0)
  }

  handleInputChange(event) {
    const target = event.target ? event.target : event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const { data } = this.state
    data[name] = value
    this.setState({ data }, () => {
      if (name === 'EMAIL') {
        this.checkUser(value)
      }
    })
  }

  changeStatus() {
    const { data } = this.state
    data.STATUS = data.STATUS === '0' ? '1' : '0'
    this.setState({ data })
  }

  checkUser(value) {
    this.setState({ buttonDisabled: true })
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      checkCustomer(value)
        .then((value) => {
          this.setState({
            showEmailExist: value?.exist,
            buttonDisabled: value?.exist
          })
        })
        .catch(() => {
          alert('Error al validar el email')
          this.setState({ buttonDisabled: false })
        })
    }, 1000)
  }

  save() {
    const { data } = this.state

    if (
      !String(data.NAME).trim().length ||
      !String(data.EMAIL).trim().length ||
      !String(data.DELIVERY_DAY).trim().length ||
      !String(data.CLINIC_NAME).trim().length ||
      (!String(data.PASSWORD).trim().length && !data.UKEY)
    ) {
      toast('Rellene todos los campos.', { type: toast.TYPE.ERROR })
      return false
    }

    // Nuevo usuario
    if (!data.UKEY) {
      //email
      if (!isEmail(data.EMAIL)) {
        toast('Formato email incorrecto', { type: toast.TYPE.ERROR })
        return false
      }
      if (!isAlphabetical(data.NAME)) {
        toast('El Nombre solo puede contener caracteres alfabeticos', {
          type: toast.TYPE.ERROR
        })
        return false
      }
      data.UKEY = 'NEW'
    }

    setCustomer(data).then((v) => {
      if (v.response && v.update) {
        toast('Datos guardados correctamente.', { type: toast.TYPE.SUCCESS })
        if (this.state.data.UKEY === 'NEW') {
          this.setState({
            data: {
              EMAIL: '',
              PASSWORD: '',
              NAME: '',
              CLINIC_NAME: '',
              TELEFONO: '',
              LOGO: '',
              DNI: '',
              DELIVERY_DAY: '',
              COUNTRY: '',
              CITY: '',
              ADDRESS: '',
              OBSERVACIONES: '',
              LEGAL_DOCUMENT: 0,
              DISCOUNT: 0,
              N_ORDERS: 0,
              STATUS: '1'
            }
          })
        }
      } else {
        toast('Error al guardar los datos.', { type: toast.TYPE.ERROR })
      }
    })
  }

  render() {
    const { data, error, showEmailExist, buttonDisabled } = this.state

    const { app } = this.props
    if (error) {
      return (
        <Redirect
          to={{
            pathname: '/404/'
          }}
        />
      )
    }

    return (
      <Fragment>
        {this.props.app.idRol === '1' ? (
          <Breadcrumb section={[{ title: 'Perfil' }]} />
        ) : (
          <Breadcrumb
            section={[
              { title: 'Clientes', url: 'clientes' },
              { title: 'Detalle del cliente' }
            ]}
          />
        )}
        <div
          className={`modal fade ${this.state.showModal ? 'in' : ''}`}
          style={{ display: this.state.showModal ? 'block' : 'none' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={() => this.setState({ showModal: false })}
                >
                  ×
                </button>
                <h4 className="modal-title">Cambiar imagen de perfil</h4>
              </div>
              <div className="modal-body">
                <UploadFileComponent
                  required={false}
                  formatRequired={[
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'image/jpeg',
                    'image/vnd.microsoft.icon',
                    'image/svg+xml',
                    'image/webp',
                    'image/PNG'
                  ]}
                  files={[]}
                  type={1}
                  name="profileattachment"
                  id="profileFile"
                  text="Arrastra y suelta aquí tu imagen de perfil."
                  maxSize="4000000"
                  multiple={false}
                  options={{ ukey: this.ukey }}
                  onUpdatedfile={(logo) => {
                    let LOGO = ''
                    if (logo && logo[0]?.filename) {
                      LOGO = logo[0].filename
                    }
                    this.setState({ data: { ...this.state.data, LOGO } })
                    if (this.props.app.idRol === '1') {
                      this.props.updateApp({ logo: LOGO })
                    }
                  }}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => this.setState({ showModal: false })}
                >
                  cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-env">
          <header className="row">
            <div className="col-sm-2">
              <a
                className="profile-picture"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.state.data.UKEY && this.setState({ showModal: true })
                }
              >
                <img
                  style={{ height: '130px', width: '130px', cursor: 'pointer' }}
                  src={
                    data.LOGO === '' || !data.LOGO
                      ? 'http://pedidos.feetsolutions.es/assets/images/avatar.png'
                      : this.pathFile + data.LOGO
                  }
                  className="img-responsive img-circle"
                />
              </a>
            </div>
            <div className="col-sm-7">
              <ul className="profile-info-sections">
                <li>
                  <div className="profile-name">
                    <strong>
                      <a href="#">{data.CLINIC_NAME}</a>
                    </strong>
                    <span>
                      <a href="#">Clínica</a>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="profile-stat">
                    <h3>{data.N_ORDERS}</h3>
                    <span>
                      <a href="#">Número de pedidos</a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </header>
          {this.state.data.UKEY && this.props.app.idRol !== '4' ? (
            this.state.legaDocumentsUploadeds ? (
              this.state.data.LEGAL_DOCUMENT === '1' ? null : this.state.data
                  .LEGAL_DOCUMENT === '-1' ? (
                <div class={`legal-warning alert-danger`}>
                  <span>
                    Por alguna razón los documentos legales adjuntados no son
                    correctos, por favor vuelva a subbirlos
                  </span>
                </div>
              ) : (
                <div class={`legal-warning alert-warning`}>
                  <span>
                    Gracias por enviar los documentos legales, el administrador
                    de la plataforma los revisará lo antes posible.
                  </span>
                </div>
              )
            ) : (
              <div class={`legal-warning alert-danger`}>
                <span>
                  Para solicitar nuevos pedidos es necesario que rellene los
                  siguientes{' '}
                  <a className="link-documents" href="#legal_documents">
                    documentos.
                  </a>
                </span>
              </div>
            )
          ) : null}
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="panel-title">Datos del usuario</div>
            </div>
            <div className="panel-body">
              <form
                role="form"
                className="form-horizontal form-groups-bordered"
              >
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-1" className="col-sm-3 control-label">
                    Persona de contacto <span className="error">*</span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="NAME"
                      className="form-control"
                      id="field-1"
                      placeholder=""
                      value={data.NAME}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-1" className="col-sm-3 control-label">
                    Nombre de clínica <span className="error">*</span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="CLINIC_NAME"
                      className="form-control"
                      id="field-1"
                      placeholder=""
                      value={data.CLINIC_NAME}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-2" className="col-sm-3 control-label">
                    Email <span className="error">*</span>
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="EMAIL"
                      className="form-control"
                      id="field-2"
                      placeholder=""
                      value={data.EMAIL}
                      maxLength={150}
                      onChange={this.handleInputChange}
                      autoComplete="off"
                    />
                  </div>
                  {this.currentEmail !== data.EMAIL &&
                  showEmailExist &&
                  buttonDisabled ? (
                    <div className="red">Este email ya existe</div>
                  ) : null}
                </div>
                <div
                  className="form-group"
                  style={{ width: '100%' }}
                  id="legal_documents"
                >
                  <label htmlFor="field-3" className="col-sm-3 control-label">
                    Contraseña{' '}
                    {!data.UKEY ? <span className="error">*</span> : null}
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="password"
                      name="PASSWORD"
                      className="form-control"
                      id="field-3"
                      placeholder=""
                      value={data.PASSWORD}
                      maxLength={150}
                      onChange={this.handleInputChange}
                      autoComplete="off"
                    />
                  </div>
                </div>
                {(this.state.data.UKEY &&
                  this.props.app.idRol === USER_ROLES.ADMIN) ||
                this.props.app.idRol === USER_ROLES.CLIENT ? (
                  <div className="form-group" style={{ width: '100%' }}>
                    <label className="col-sm-3 control-label">
                      Documentos legales
                    </label>
                    <div className="col-sm-5">
                      {this.state.data.LEGAL_DOCUMENT === '1' &&
                      app.idRol !== USER_ROLES.ADMIN ? null : (
                        <p>
                          Firma los siguientes contratos conforme estás de
                          acuerdo con nuestras condiciones,{' '}
                          <a
                            href={ENV.assetFiles + '/legal-documents.zip'}
                            className="link-documents"
                          >
                            descargar documentos
                          </a>
                          . Una ves firmados arrastralos en la siguiente
                          cuadrícula.
                        </p>
                      )}
                      <UploadFileComponent
                        required={false}
                        formatRequired={[
                          'application/pdf',
                          'application/vnd.ms-powerpoint',
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                          'application/msword',
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        ]}
                        files={[]}
                        type={2}
                        name="profileattachment"
                        id="profileFile"
                        text="Arrastra y suelta aquí los documntos legales."
                        maxSize="15000000"
                        multiple={true}
                        options={{ ukey: this.ukey }}
                        readOnly={
                          this.state.data.LEGAL_DOCUMENT === '1' &&
                          (app.idRol !== USER_ROLES.ADMIN ||
                            app.idRol !== USER_ROLES.CLIENT)
                        }
                        onUpdatedfile={(files) =>
                          this.setState({
                            legaDocumentsUploadeds:
                              files.length !== 0 ? true : false
                          })
                        }
                        onFilesLoaded={(files) => {
                          this.setState({
                            legaDocumentsUploadeds:
                              files.length !== 0 ? true : false
                          })
                        }}
                      />
                      {app.idRol === USER_ROLES.ADMIN ? (
                        <div>
                          Aceptar los documentos:{' '}
                          <button
                            type="button"
                            name="LEGAL_DOCUMENT"
                            value={1}
                            class={`btn btn-green ${
                              this.state.data.LEGAL_DOCUMENT === '1'
                                ? 'border-selected'
                                : null
                            }`}
                            onClick={this.handleInputChange}
                          >
                            {' '}
                            Si{' '}
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <button
                            type="button"
                            class={`btn btn-red ${
                              this.state.data.LEGAL_DOCUMENT === '-1'
                                ? 'border-selected'
                                : null
                            }`}
                            name="LEGAL_DOCUMENT"
                            value={-1}
                            onClick={this.handleInputChange}
                          >
                            {' '}
                            No{' '}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-4" className="col-sm-3 control-label">
                    Nombre Fiscal
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="FISCAL_NAME"
                      className="form-control"
                      id="field-4"
                      placeholder=""
                      value={data.FISCAL_NAME}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-4" className="col-sm-3 control-label">
                    Dirección Fiscal
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="FISCAL_ADDRESS"
                      className="form-control"
                      id="field-4"
                      placeholder="Dirección, CP y Localidad"
                      value={data.FISCAL_ADDRESS}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-4" className="col-sm-3 control-label">
                    Teléfono
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="TELEFONO"
                      className="form-control"
                      id="field-4"
                      placeholder=""
                      value={data.TELEFONO}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-5" className="col-sm-3 control-label">
                    D.N.I / N.I.F
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="DNI"
                      className="form-control"
                      id="field-5"
                      placeholder=""
                      value={data.DNI}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-6" className="col-sm-3 control-label">
                    Dirección de envío
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="ADDRESS"
                      className="form-control"
                      id="field-6"
                      placeholder=""
                      value={data.ADDRESS}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-7" className="col-sm-3 control-label">
                    Provincia
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="COUNTRY"
                      className="form-control"
                      id="field-7"
                      placeholder=""
                      value={data.COUNTRY}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-8" className="col-sm-3 control-label">
                    Ciudad
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="CITY"
                      className="form-control"
                      id="field-8"
                      placeholder=""
                      value={data.CITY}
                      maxLength={150}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-8" className="col-sm-3 control-label">
                    Codigo Postal
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      name="POSTAL_CODE"
                      className="form-control"
                      id="field-8"
                      placeholder=""
                      value={data.POSTAL_CODE}
                      maxLength={10}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ width: '100%' }}>
                  <label htmlFor="field-9" className="col-sm-3 control-label">
                    Periodo de facturación: <span className="error">*</span>
                  </label>
                  <div className="col-sm-5">
                    <Select
                      id="field-9"
                      name="DELIVERY_DAY"
                      hideLabel
                      value={this.state.data.DELIVERY_DAY}
                      options={this.deliveryOptions}
                      dimensions={{
                        label: 0,
                        component: 12
                      }}
                      onChange={this.handleInputChange}
                      notSaveInOrder
                    />
                  </div>
                </div>
                {app.idRol === '99' ? (
                  <Fragment>
                    <div className="form-group" style={{ width: '100%' }}>
                      <label
                        htmlFor="field-10"
                        className="col-sm-3 control-label"
                      >
                        Descuento (%)
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="number"
                          name="DISCOUNT"
                          className="form-control"
                          id="field-10"
                          placeholder=""
                          value={data.DISCOUNT}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{ width: '100%' }}>
                      <label
                        htmlFor="field-11"
                        className="col-sm-3 control-label"
                      >
                        Activar
                      </label>
                      <div className="col-sm-5">
                        <div
                          className="make-switch is-radio switch-small has-switch"
                          data-text-label="<i class='entypo-user'></i>"
                        >
                          <div
                            class={`switch-animate ${
                              this.state.data.STATUS === '1'
                                ? 'switch-on'
                                : 'switch-off'
                            }`}
                            onClick={this.changeStatus}
                            style={{ zIndex: 0 }}
                          >
                            <input
                              id="field-10"
                              type="radio"
                              name="STATUS"
                              checked={`${
                                this.state.data.STATUS === '1' ? 'checked' : ''
                              }`}
                            />
                            <span className="switch-left switch-small">ON</span>
                            <label class={`switch-small`}>
                              <i className="entypo-user"></i>
                            </label>
                            <span className="switch-right switch-small">
                              OFF
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : null}
                {app.idRol !== '1' ? (
                  <div className="form-group" style={{ width: '100%' }}>
                    <label
                      htmlFor="field-11"
                      className="col-sm-3 control-label"
                    >
                      Observaciones
                    </label>
                    <div className="col-sm-5">
                      <textarea
                        rows="7"
                        cols="50"
                        name="OBSERVACIONES"
                        className="form-control"
                        id="field-11"
                        value={data.OBSERVACIONES}
                        onChange={this.handleInputChange}
                      >
                        {data.OBSERVACIONES}
                      </textarea>
                    </div>
                  </div>
                ) : null}
                {app.idRol === '99' || app.idRol === '4' ? (
                  <button
                    onClick={() => this.props.history.goBack()}
                    type="button"
                    className="btn btn-primary pull-left"
                    style={{ marginTop: '15px' }}
                  >
                    <i className="entypo-left-open"></i> Atrás
                  </button>
                ) : (
                  <button
                    onClick={() => this.props.history.goBack()}
                    type="button"
                    className="btn btn-primary pull-left"
                    style={{ marginTop: '15px' }}
                  >
                    <i className="entypo-left-open"></i> Atrás
                  </button>
                )}
                {app.idRol === '99' || app.idRol === '1' ? (
                  <button
                    type="button"
                    className="btn btn-green pull-right"
                    style={{ marginTop: '15px' }}
                    onClick={this.save}
                    disabled={buttonDisabled}
                  >
                    Guardar <i className="entypo-check"></i>
                  </button>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

const mapDispatchToProps = {
  updateApp: updateAppAction
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail)
