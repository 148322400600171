import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

// COMPONENTS
import Alert from '../../../components/alert/alert'
import Breadcrumb from '../../../components/breadcrumb/breadcrumb'
import Wizard from '../../../components/wizard/wizard'

// PAGES
import Confirmation from '../confirmation/confirmation'

// DATA
import {
  customer_selector,
  datos_personales,
  forros,
  horma_material_base
} from '../../../data'
import { data_antepie } from './foot-selectors/antepie'
import { data_alza, data_contencion } from './foot-selectors/contencion'
import { data_mediopie } from './foot-selectors/mediopie'
import { data_retropie } from './foot-selectors/retropie'

// Import customers
import { getCustomers, getOrders } from './../../../resources/services'

// Actions
import {
  resetStoreAction,
  updateOrderAction
} from '../../../redux/actions/orders'

import { Loading } from '../../../components/loading/loading'
import { ORDER_TYPE } from '../../../core/constants/roles'
import './orders.css'

import { isAlphabetical } from '../../../core/utils/validations'

class Order extends Component {
  constructor(props) {
    super(props)
    this.mounted = false

    // Asignamos Ukey en uploadfiles
    const dp = datos_personales.map((v) => {
      const toReturn = { ...v }
      toReturn.components = v.components.map((c) => {
        if (c.properties?.id) {
          if (c.properties.id === 'uploadFile') {
            c.properties.options = { ukey: props.match.params.ukey }
          }
        }
        return c
      })
      return toReturn
    })

    // Asignamos Ukey en uploadfiles
    const frr = forros.map((v) => {
      const toReturn = { ...v }
      toReturn.components = v.components.map((c) => {
        if (c.properties?.id) {
          if (c.properties.id === 'uploadFile') {
            c.properties.options = { ukey: props.match.params.ukey }
          }
        }
        return c
      })
      return toReturn
    })

    this.data = [
      {
        title: 'Datos personales',
        group: dp
      },
      {
        title: 'Horma / Material base',
        group: horma_material_base
      },
      {
        title: 'Forros',
        group: frr
      },
      {
        title: 'Antepié',
        canvas: data_antepie
      },
      {
        title: 'Mediopié',
        canvas: data_mediopie
      },
      {
        title: 'Retropié',
        canvas: data_retropie
      },
      {
        title: 'Contención / Estabilización / Alza',
        canvas: [
          { title: 'Contención/Estabilización', data: data_contencion },
          { title: 'Alza', data: data_alza }
        ]
      }
    ]
    this.dataToConfirmation = [
      {
        title: 'Datos personales',
        group: datos_personales
      },
      {
        title: 'Horma / Material base',
        group: horma_material_base
      },
      {
        title: 'Forros',
        group: forros
      },
      {
        title: 'Antepié',
        canvas: data_antepie
      },
      {
        title: 'Mediopié',
        canvas: data_mediopie
      },
      {
        title: 'Retropié',
        canvas: data_retropie
      },
      {
        title: 'Contención',
        canvas: data_contencion
      },
      {
        title: 'Alza',
        canvas: data_alza
      }
    ]
    this.allCustomers = []
    this.state = {
      step: 0,
      loading: true,
      data: {},
      dataToConfirmation: {},
      modalOpt: {
        show: false,
        status: '',
        message: ''
      }
    }

    this.setUrlYoutubeInAllItems = this.setUrlYoutubeInAllItems.bind(this)
  }

  setUrlYoutubeInAllItems = (data, prices) => {
    return data
  }

  componentDidMount() {
    this.mounted = true
    const { idRol } = this.props.app
    const cs = customer_selector
    if (!this.props.order.fromFavorite) {
      this.props.resetStore()
    }

    // GET ORDER PROMISE
    const goPromise = new Promise((resolve, reject) => {
      const u = this.props.match.params.ukey
      getOrders(u ? u : 'new')
        .then((result) => {
          if (!result.response) {
            reject('No se ha encontrado el pedido')
          }
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })

    // GET CUSTOMER PROMISE
    let gcProise = new Promise((resolve) => {
      resolve(this.data)
    })
    if (parseInt(idRol) === 99 || parseInt(idRol) === 4) {
      gcProise = new Promise((resolve, reject) => {
        getCustomers().then((result) => {
          if (result.response) {
            this.allCustomers = result.customers.map((v) => {
              return { value: v.UKEY, label: v.NAME, discount: v.DISCOUNT }
            })
            cs.components[0].properties.options = this.allCustomers
            const data = this.data.slice()
            if (data[0].group[0].title !== 'Cliente') {
              data[0].group.splice(0, 0, { ...cs })
            }
            resolve(data)
          } else {
            reject('No se han podido recuperar los clientes.')
          }
        })
      })
    }

    Promise.all([goPromise, gcProise])
      .then((values) => {
        // Si hay datos del pedido updateamos
        const valueParsed = values[0].orders[0]
          ? JSON.parse(values[0].orders[0].DATA?.replaceAll('\n', ' '))
          : []
        const orderType = values[0].orders[0]?.ORDER_TYPE ?? ORDER_TYPE.NEW
        let sended =
          (values[0]?.orders[0]?.STATUS != 5 && values[0]?.orders?.length) ||
          orderType === ORDER_TYPE.FIX

        if (this.props.app.idRol !== '1' && orderType !== ORDER_TYPE.FIX) {
          sended =
            values[0]?.orders[0]?.STATUS == 1 ||
            values[0]?.orders[0]?.STATUS == 5 ||
            !values[0]?.orders?.length
              ? false
              : true
        }

        if (this.props.order.fromFavorite) {
          this.props.updateOrder({
            order_type: orderType,
            materials: values[0].prices,
            customers: this.allCustomers,
            fromFavorite: false,
            discount: values[0].orders[0]?.PRICE_DISCOUNT
          })
        } else {
          this.props.updateOrder({
            order_type: orderType,
            ...valueParsed,
            materials: values[0].prices,
            id_series: values[0].orders[0] ? values[0].orders[0].ID_SERIES : 0,
            customers: this.allCustomers,
            sended,
            status: values[0]?.orders[0]?.STATUS,
            discount: values[0].orders[0]?.PRICE_DISCOUNT
          })
        }

        const dataParsed = this.setUrlYoutubeInAllItems(
          values[1],
          values[0].prices
        )
        setTimeout(() => {
          this.mounted &&
            this.setState({
              data: dataParsed,
              dataToConfirmation: this.dataToConfirmation,
              loading: false
            })
        }, 2000)
      })
      .catch((err) => {
        toast(String(err), { type: toast.TYPE.ERROR })
      })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.order.id_cliente !== this.props.app.idUser &&
      this.props.order.nombre_del_paciente &&
      this.props.order.apellidos_del_paciente &&
      this.props.app.idRol === '1' &&
      isAlphabetical(this.props.order.nombre_del_paciente) &&
      isAlphabetical(this.props.order.apellidos_del_paciente)
    ) {
      this.props.updateOrder({ id_cliente: this.props.app.idUser })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    this.setState({
      data: {},
      dataToConfirmation: {},
      loading: true
    })

    /*
    const dataToSend = JSON.stringify({
      datos_personales: getObject(datos_personales),
      horma_material_base: getObject(horma_material_base),
      forros: getObject(forros),
      antepie: getObject([data_antepie]),
      contencion: getObject([data_contencion]),
      alza: getObject([data_alza]),
      mediopie: getObject([data_mediopie]),
      retropie: getObject([data_retropie]),
      UKEY: localStorage.getItem('ukey'), 
      order: this.props.order
    });
    createPdf(dataToSend).then(v => this.setState({createPdf: true}));*/
  }

  getPrice = (price = 0) => {
    return price > 0 ? (+price).toFixed(2) : 0
  }

  render() {
    const wizardClass = ''
    const price =
      this.props.order.order_type === ORDER_TYPE.FIX
        ? this.props.order.orderToFixPrice
        : this.props.order.price
    return (
      <Fragment>
        <Breadcrumb
          section={[
            { title: 'Pedidos', url: 'pedidos/' },
            { title: 'Detalle del pedido' }
          ]}
        />
        <div className="order-content">
          <Alert {...this.state.modalOpt} />
          <h2 className="title-section">Configurador</h2>
          <div className="price-container">
            Precio: <span className="price">{this.getPrice(price)}€</span>
          </div>
          {this.state.loading ? (
            <Loading />
          ) : (
            <Fragment>
              {this.props.order.sended || this.props.order.step === 1 ? (
                <Confirmation data={this.state.dataToConfirmation} />
              ) : (
                <div className={wizardClass}>
                  <Wizard data={this.state.data} />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  order: state.orders,
  app: state.app
})

const mapDispatchToProps = {
  updateOrder: updateOrderAction,
  resetStore: resetStoreAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)
