import React from "react";
import Table from "../../../components/table/table";
import { formatTime } from "../../../core/utils/date";
import { calculatePriceWithIva } from "../../../core/utils/price";
import { environment } from "../../../deploy/environment";

export const DetailFinalizadosPedidosModal = (props) => {
  const columns = [
    {
      name: "Nº Lote",
      selector: "ID_SERIES",
      sortable: true,
    },
    {
      name: "Nombre",
      selector: "NOMBRE_PACIENTE",
      sortable: true,
    },
    {
      name: "Apellidos",
      selector: "APELLIDOS_PACIENTE",
      sortable: true,
    },
    {
      name: "Fecha creación",
      cell: (row) => {
        return formatTime(row.FECHA_CREACION);
      },
      sortable: true,
    },
    {
      name: "Estado",
      selector: "STATUS",
      sortable: true,
      cell: (row) => (
        <div className={`status-${row.STATUS}`}>{row.STATUS_NAME}</div>
      ),
    },
    {
      name: "Precio",
      selector: "PRICE",
      sortable: true,
      cell: (row) => `${calculatePriceWithIva(row.PRICE, row.PRICE_DISCOUNT)}€`,
    },
    {
      name: "Etiqueta",
      cell: (row) => {
        return [
          <a
            className="btn btn-default"
            target="_blank"
            href={
              environment.urlResources +
              row.UKEY +
              "/label-number-" +
              row.ID_SERIES +
              ".pdf"
            }
          >
            <i className="entypo-download"></i>
          </a>,
          <button
            className="btn btn-default"
            style={{ marginLeft: 15 }}
            onClick={() =>
              window.confirm(`Seguro que deseas enviar este pedido?`) &&
              props.onSendOrder(row.UKEY, 7)
            }
          >
            <i className="entypo-right"></i>
          </button>,
          <button
            className="btn btn-default"
            style={{ marginLeft: 15 }}
            onClick={() =>
              window.confirm(
                `Seguro que deseas entegar en mano este pedido?`
              ) && props.onSendOrderInHand(row.UKEY, 8)
            }
          >
            <i className="entypo-bag"></i>
          </button>,
        ];
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div
      className={`modal fade ${props.show ? "in" : ""}`}
      style={{ display: props.show ? "block" : "none" }}
    >
      <div className="modal-dialog" style={{ width: "90%" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Pedidos asociados al envio</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => props.onHide()}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <Table
              keyFilter={"FECHA_CREACION"}
              data={props.data ? props.data : []}
              columns={columns}
              title="Pedidos"
              pagination
            />
          </div>
        </div>
      </div>
    </div>
  );
};
