import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import Table from '../../components/table/table'
import { environment } from '../../deploy/environment'
import {
  changeStatus,
  generateProductionResource,
  getOrders
} from './../../resources/services'

// CSS
import moment from 'moment'
import { formatTime } from '../../core/utils/date'
import './index.css'

class Fabricacion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      showConfirm: false,
      showConfirm1: false,
      showConfirm2: false,
      showConfirm3: false,
      showModal1: false,
      idDocument1: 0,
      ukeyDocument1: '',
      showModal2: false,
      idDocument2: 0,
      ukeyDocument2: '',
      showModal3: false,
      idDocument3: 0,
      ukeyDocument3: '',
      error: false,
      loading: true
    }
    this.interval = setInterval(() => {}, 0)
    this.showDocument = this.showDocument.bind(this)
    this.setStatus = this.setStatus.bind(this)
    this.confirmStatus = this.confirmStatus.bind(this)

    this.columns1 = [
      {
        name: 'Nº Lote',
        selector: 'ID_SERIES',
        sortable: true
      },
      {
        name: 'Fecha de creación',
        selector: 'FECHA_CREACION',
        cell: (row) => {
          return formatTime(row.FECHA_CREACION)
        },
        sortable: true
      },
      {
        cell: (row) => {
          return [
            <button
              type="button"
              className="btn btn-default"
              key={`row-buton-1-${row.UKEY}`}
              onClick={() => this.showDocument(row, 1)}
            >
              <i className="entypo-eye"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      }
    ]

    this.columns2 = [
      {
        name: 'Nº Lote',
        selector: 'ID_SERIES',
        sortable: true
      },
      {
        name: 'Fecha de creación',
        selector: 'FECHA_CREACION',
        cell: (row) => {
          return formatTime(row.FECHA_CREACION)
        },
        sortable: true
      },
      {
        cell: (row) => {
          return [
            <button
              type="button"
              className="btn btn-default"
              key={`row-buton-2-${row.UKEY}`}
              onClick={() => this.showDocument(row, 2)}
            >
              <i className="entypo-eye"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      }
    ]

    this.columns3 = [
      {
        name: 'Nº Lote',
        selector: 'ID_SERIES',
        sortable: true
      },
      {
        name: 'Fecha de creación',
        selector: 'FECHA_CREACION',
        cell: (row) => {
          return formatTime(row.FECHA_CREACION)
        },
        sortable: true
      },
      {
        cell: (row) => {
          return [
            <button
              type="button"
              className="btn btn-default"
              key={`row-buton-3-${row.UKEY}`}
              onClick={() => this.showDocument(row, 3)}
            >
              <i className="entypo-eye"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true
      }
    ]
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      getOrders(null, 2)
        .then((result) => {
          if (result.response) {
            this.setState({
              data: result.orders
            })
          } else {
            this.setState({ error: true })
            if (!this.state.error) {
              toast('Se ha producido un error al realizar la consulta', {
                type: toast.TYPE.ERROR
              })
            }
          }
          this.setState({ loading: false })
        })
        .catch((error) => {
          this.setState({ loading: false })
          console.error('getOrders-->', error)
        })
    }, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  openFullscreen() {
    const elem = document.getElementById('divFullscreen')
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen()
    }
  }

  showDocument(row, table) {
    this.setState({
      [`showModal${table}`]: true,
      [`idDocument${table}`]: row.ID_SERIES,
      [`ukeyDocument${table}`]: row.UKEY
    })
  }

  setStatus = () => {
    const { currentConfirm } = this.state
    changeStatus({ id: currentConfirm, status: 3 }).then((response) => {
      if (response.response === 1 && response.update === 1) {
        const data = this.state.data.filter((v) => v.UKEY !== currentConfirm)
        let newState = { data }
        if (this.state.ukeyDocument1 === currentConfirm) {
          newState = { ...newState, showModal1: false }
        }
        if (this.state.ukeyDocument2 === currentConfirm) {
          newState = { ...newState, showModal2: false }
        }
        if (this.state.ukeyDocument3 === currentConfirm) {
          newState = { ...newState, showModal3: false }
        }
        this.setState({ ...newState })
        toast('El pedido se ha enviado correctamente a revisión', {
          type: toast.TYPE.SUCCESS
        })
      } else {
        toast('No se ha podido actualizar el status del pedido', {
          type: toast.TYPE.ERROR
        })
      }
    })
  }

  confirmStatus(id) {
    this.setState({ showConfirm: true, currentConfirm: id })
  }

  render() {
    return (
      <Fragment>
        {this.props.app.idRol === '99' || this.props.app.idRol === '4' ? (
          <Breadcrumb
            section={[{ title: 'Fabricación', url: 'fabricacion' }]}
          />
        ) : (
          <div className="breadcrumb bc-3" />
        )}
        <div className="row divFullScreen" id="divFullscreen">
          <div className="row-pdf">
            <button
              className="btn btn-default"
              onClick={() => {
                const date = moment().format('YYYY-MM-DD')
                generateProductionResource(date).then((val) => {
                  console.log(val)
                  const urlResources = `${environment.urlProductionResource}${date}/orders-production.pdf`
                  window.open(urlResources, '_blank')
                })
              }}
            >
              Exportar PDF
            </button>
          </div>
          <div className="button-fullscreen" onClick={this.openFullscreen}>
            <i className="entypo-popup"></i>
          </div>
          <div className="col-xs-6 nopadd">
            <Table
              keyFilter={'ID_SERIES'}
              data={this.state.data}
              columns={this.columns1}
              title=""
              pagination
              minWidth="10px"
              inputSearchShort
            />
            {this.state.showModal1 ? (
              <div
                className="customModal"
                style={{ display: this.state.showModal1 ? 'block' : 'none' }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() =>
                          this.setState({
                            showModal1: false,
                            showConfirm1: false
                          })
                        }
                      >
                        ×
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({
                            showConfirm1: true,
                            currentConfirm: this.state.ukeyDocument1
                          })
                        }
                        className=" btn btn-green btn-icon"
                      >
                        Finalizar fabricación
                        <i className="entypo-check"></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      {this.state.showConfirm1 ? (
                        <div className="modal-to-confirm">
                          <div className="modal-body">
                            Seguro que desea finalizar el pedido? <br /> el
                            pedido se enviará a revisión <br />
                            <button
                              type="button"
                              className="btn btn-default"
                              data-dismiss="modal"
                              onClick={() =>
                                this.setState({ showConfirm1: false })
                              }
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={this.setStatus}
                            >
                              Aceptar
                            </button>
                          </div>
                        </div>
                      ) : (
                        <object
                          data={`${environment.root}/api/files/order-attachment/${this.state.ukeyDocument1}/order-number-${this.state.idDocument1}.pdf`}
                          type="application/pdf"
                          width="100%"
                          style={{ height: 'calc(100vh - 50px)' }}
                        />
                      )}
                      {/*<object data={`${environment.root}/api/files/order-pdfs/ejemplo.pdf`} type="application/pdf" width="100%" style={{"height":"calc(100vh - 50px)"}} />*/}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-xs-6 nopadd">
            <Table
              keyFilter={'ID_SERIES'}
              data={this.state.data}
              columns={this.columns2}
              title=""
              pagination
              inputSearchShort
            />
            {this.state.showModal2 ? (
              <div
                className="customModal"
                style={{ display: this.state.showModal2 ? 'block' : 'none' }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() => this.setState({ showModal2: false })}
                      >
                        ×
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          this.setState({
                            showConfirm2: true,
                            currentConfirm: this.state.ukeyDocument2
                          })
                        }
                        className=" btn btn-green btn-icon"
                      >
                        Finalizar fabricación
                        <i className="entypo-check"></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      {this.state.showConfirm2 ? (
                        <div className="modal-to-confirm">
                          <div className="modal-body">
                            Seguro que desea finalizar el pedido? <br /> el
                            pedido se enviará a revisión <br />
                            <button
                              type="button"
                              className="btn btn-default"
                              data-dismiss="modal"
                              onClick={() =>
                                this.setState({ showConfirm2: false })
                              }
                            >
                              Cancelar
                            </button>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={this.setStatus}
                            >
                              Aceptar
                            </button>
                          </div>
                        </div>
                      ) : (
                        <object
                          data={`${environment.root}/api/files/order-attachment/${this.state.ukeyDocument2}/order-number-${this.state.idDocument2}.pdf`}
                          type="application/pdf"
                          width="100%"
                          style={{ height: 'calc(100vh - 50px)' }}
                        />
                      )}
                      {/*<object data={`${environment.root}/api/files/order-pdfs/ejemplo.pdf`} type="application/pdf" width="100%" style={{"height":"calc(100vh - 50px)"}} />*/}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/*<div className="col-xs-4 nopadd">
            <Table
              keyFilter={'ID_SERIES'}
              data={this.state.data}
              columns={this.columns3}
              title=""
              pagination
              inputSearchShort
            />
            {
              this.state.showModal3
                ? <div className="customModal" style={{"display":this.state.showModal3 ? 'block' : 'none'}}>
                    <div className="modal-dialog"> 
                      <div className="modal-content">
                        <div className="modal-header"> 
                          <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => this.setState({showModal3: false, showConfirm3: false})}>×</button>
                          <button type="button" onClick={() => this.setState({showConfirm3: true, currentConfirm: this.state.ukeyDocument3})} className=" btn btn-green btn-icon">
                                  Finalizar fabricación
                          <i className="entypo-check"></i></button>
                        </div>
                        <div className="modal-body">
                        {
                          this.state.showConfirm3
                            ? <div className="modal-to-confirm">
                                <div className="modal-body">
                                  Seguro que desea finalizar el pedido? <br /> el pedido se enviará a revisión <br />
                                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => this.setState({showConfirm3: false})}>Cancelar</button>
                                  <button type="button" className="btn btn-info" onClick={this.setStatus}>Aceptar</button>
                                </div>
                              </div>
                            : <object data={`${environment.root}/api/files/order-attachment/${this.state.ukeyDocument3}/order-number-${this.state.idDocument3}.pdf`} type="application/pdf" width="100%" style={{"height":"calc(100vh - 50px)"}} />
                        }
                        {/*<object data={`${environment.root}/api/files/order-pdfs/ejemplo.pdf`} type="application/pdf" width="100%" style={{"height":"calc(100vh - 50px)"}} />*/}
          {/*</div>
                      </div>
                    </div>
                  </div>
                : null
            }
          </div> */}
        </div>
        <div
          className={`modal ${this.state.showConfirm ? 'fade in' : null}`}
          id="modal-1"
          style={
            this.state.showConfirm
              ? { display: 'block', paddingRight: '15px', zIndex: '2041' }
              : null
          }
          onClick={() => this.setState({ showConfirm: false })}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                Estas seguro de finalizar el pedido? el pedido se enviará a
                revisión
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => this.setState({ showConfirm: false })}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={this.setStatus}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.state.loading ? (
            <div className="gooey">
              <span className="dot"></span>
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          ) : null}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(Fabricacion)
