const defaultStore = {
  step: 0,
  sended: false,
  id: '', //Math.random().toString(36).substr(2, 10)
  id_cliente: '',
  orderAttachment: [],
  orderLogoAttachment: [],
  horma: 'HC',
  adaptacion_del_arco: 'HAA',
  horma_longitud: 'HLC',
  adaptacion_del_arco_type: 'HAA0',
  longitud_material_base: 'HLMBR',
  material_base: '',
  forro_superior_color: 'Negro',
  forro_superior: 'FSE2',
  data_send: '',
  materialInterior: 'FIMFC',
  materialInteriorType: 'FIP',
  altura_cazoleta: '10',
  altura_cazoleta_pie: 'Bi',
  materialInterior: 'FIVA',
  // Antepie
  /*cuña_poron_antepie: "cuna_poron_antepie",
  poron_carril_antepie: 'poron_carril_antepie',
  cuña_grosor: '2_mm',
  // Mediopie
  grosor_extrinseco: '3_mm',
  grosor_intrinseco: '4_mm',
  // Retropie
  grosor_cazoleta: 10,
  cazoleta: '',*/
  // Price
  price: 0,
  status: 0,
  materials: []
}
const orders = (state = defaultStore, action) => {
  switch (action.type) {
    case 'UPDATE_ORDER':
      return Object.assign({}, state, action.data)
    case 'DELETE_ORDER':
      return action.data
    case 'RESET_STORE':
      return defaultStore
    default:
      return state
  }
}

export default orders
