
import React, { Component, Fragment } from 'react';
import Table from '../../components/table/table';
import { connect } from 'react-redux';
import {getOrders, setPrice, changeStatus } from './../../resources/services'
import { toast } from 'react-toastify';
import { environment } from '../../deploy/environment'
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import './index.css';
import { formatTime } from '../../core/utils/date';

class Control extends Component {

  constructor(props){
    super(props);
    this.state = {
      loadError: false,
      data:[],
      showModal: false,
      optionsModal:{
        "option-1": false,
        "option-2": false,
        "option-3": false,
        "option-4": false,
        "option-5": false,
        "option-6": false,
      },
      currentRow: {},
      name: '',
      price: '',
      showPriceError: false,
      idDocument: '',
      ukeyDocument: ''
    };

    getOrders(null,3)
    .then((result) => {
      if(result.response){
        this.setState({
          data: result.orders
        })
      }else{
        toast("Se ha producido un error al realizar la consulta",{type: toast.TYPE.ERROR})
      }
    })

    this.editOrder = this.editOrder.bind(this);
    this.checkPriceAndSave = this.checkPriceAndSave.bind(this);

    this.columns = [
      {
        name: 'Nº Lote',
        selector: 'ID_SERIES',
        sortable: true,
        cell: row => <div className={`${row.DATE_DIFF > 6 && row.STATUS !== 4 ? 'red' : ''}`}>{row.ID_SERIES}</div>
      },
      {
        name: 'Customer',
        selector: 'CLINIC_NAME',
        sortable: true,
      },
      {
        name: 'Nombre',
        selector: 'NOMBRE_PACIENTE',
        sortable: true,
      },
      {
        name: 'Apellidos',
        selector: 'APELLIDOS_PACIENTE',
        sortable: true,
      },
      {
        name: 'Fecha creación',
        selector: 'FECHA_CREACION',
        cell: row => {
          return formatTime(row.FECHA_CREACION)
        },
        sortable: true,
      },
      {
        cell: (row) => {
          return [
            <button 
              type="button"
              className="btn btn-default"
              onClick={() => this.editOrder(row)}><i className="entypo-pencil"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

  }

  editOrder(row){
    this.setState({currentRow: row, showModal: true})
  }

  checkPriceAndSave(){
    let realPrice = this.state.price.trim()
    let showPriceError = realPrice.indexOf(",") !== -1 ? true : false;
    showPriceError = realPrice.split(".").lenght > 2 ? true : showPriceError
    this.setState({price: realPrice, showPriceError}, () => {
      if(this.state.showPriceError){
        return
      }
      setPrice({id: this.state.currentRow.ID, price: this.state.price, name: this.state.name})
      .then((result) => {
        if(result.response && result.updated > 0){
          let { data } = this.state;
          const newData = data.map(v => {
            if(v.ID === this.state.currentRow.ID){
              return { ID: v.ID, NAME: this.state.name, PRICE: this.state.price}
            }
            return v;
          });
          this.setState({data: newData, showModal: false, currentRow:{}});
        }else {
          toast("No se ha podido modificar el producto",{type: toast.TYPE.ERROR})
        }
      })
    })
  }

  setOptionModal = e => {
    const { optionsModal } = this.state
    optionsModal[e.target.id] = e.target.checked
    this.setState({optionsModal})
  }

  sendToEnd = () => {
    const id = this.state.currentRow.UKEY;
    const dataToSend = {id, status: 4, UKEY: localStorage.getItem('ukey')}
    window.confirm(`Estás seguro de que deseas finalizar el pedido?`) && changeStatus(dataToSend).then((result) => {
      if(result.response !== 0 && result.update !== 0){
        const { data } = this.state
        const newData = data.filter(v => v.UKEY !== id)
        this.setState({currentRow: {}, data: newData, showModal: false});
      } else {
        toast("No se ha podido obtener los estados del pedidio",{type: toast.TYPE.ERROR})
      }
    })
  }

  render() {
    console.log('this.state.', this.state.optionsModal)
    const disabled = Object.values(this.state.optionsModal).some(v => v === false)
    return (
      <Fragment>
        {this.props.app.idRol === "99"
          ? <Breadcrumb
              section={[
                {title: "Control de calidad", url: "control-de-calidad"},
              ]}
            />
          : null
        }
        <Table
          keyFilter={'NAME'}
          data={this.state.data}
          columns={this.columns}
          title="Control de calidad"
          pagination
        />
        <div className={`modal control-modal fade ${this.state.showModal ? 'in' : ''}`} style={{"display":this.state.showModal ? 'block' : 'none'}}>
          <div className="modal-dialog"> 
            <div className="modal-content">
              <div className="modal-header"> 
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => this.setState({showModal: false, currentRow:{}})}>×</button>
                <h4 className="modal-title">Verificación del producto</h4>
              </div>
              <div className="modal-body">
                <div class="tbl">
                  <div className="td">
                    <div className="form-content">
                      <div style={{"marginRight": "10px", "marginBottom": "15px"}}>Identificador del pedido: <b>{this.state.currentRow.ID_SERIES}</b></div>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" id="option-1" onChange={this.setOptionModal}/>La forma de la plantilla coincide con el molde/diseño:
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" id="option-2" onChange={this.setOptionModal}/>Los materiales coinciden con el pedido:
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" id="option-3" onChange={this.setOptionModal}/>Las descargas coinciden con el pedido realizado:
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" id="option-4" onChange={this.setOptionModal}/>El estabilizador esta pulido a 45º:
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" id="option-5" onChange={this.setOptionModal}/>La plantilla esta estable?:
                      </label>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" id="option-6" onChange={this.setOptionModal}/>Acabados (limpieza, pulido, gabado):
                      </label>
                    </div>
                    <button type="button" className={`btn btn-danger`} data-dismiss="modal" onClick={() => this.setState({showModal: false})} style={{marginRight: '5px'}}>Cancelar</button>
                    <button type="button" disabled={disabled} className={`btn btn-${disabled ? 'default' : 'green'}`} data-dismiss="modal" onClick={this.sendToEnd}>Guardar</button>
                  </div>
                  <div className="td">
                   <object data={`${environment.root}/api/files/order-attachment/${this.state.currentRow.UKEY}/order-number-${this.state.currentRow.ID_SERIES}.pdf`} type="application/pdf" width="100%" style={{"height":"calc(100vh - 150px)"}} />
                    {/*<object data={`${environment.root}/api/files/order-pdfs/ejemplo.pdf`} type="application/pdf" width="100%" style={{"height":"calc(100vh - 50px)"}} />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app
});

export default connect(mapStateToProps)(Control);