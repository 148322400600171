import React from 'react'
import { DuplicateOrderButton } from '../../components/duplicate-order-button'
import { DetailOrderButton } from '../../components/detail-order-button'
import { ShowOrderModalButton } from '../../components/show-order-modal-button'
import { DownloadOrderButton } from '../../components/download-order-button'
import {
  ORDER_STATUS,
  ORDER_TYPE,
  USER_ROLES
} from '../../../../core/constants/roles'
import { DeleteOrderButton } from '../../components/delete-order-button'

export const getTableCommands = (row, idRol, setState) => {
  // status 5 = propuesta
  // status 1 = diseño
  const isPossibleChangeDateToSend = (orderStatus) => {
    return (
      orderStatus === ORDER_STATUS.DESIGN ||
      orderStatus === ORDER_STATUS.QA ||
      orderStatus === ORDER_STATUS.FABRICATION
    )
  }
  const currentStatus = row.STATUS
  const currentOrderType = row.ORDER_TYPE

  if (idRol === USER_ROLES.ADMIN || idRol === USER_ROLES.DESIGNER) {
    return [
      <ShowOrderModalButton
        key={`show-order-button-${row.UKEY}`}
        id={row.ID}
        onClick={() =>
          setState({
            showModal: true,
            rowSelected: row
          })
        }
      />,
      <DownloadOrderButton
        ukey={row.UKEY}
        key={`download-order-button-${row.UKEY}`}
      />
    ]
  } else {
    switch (row.STATUS) {
      case ORDER_STATUS.PROPOSAL:
        return (
          <>
            <DetailOrderButton
              key={`detail-order-button-${row.UKEY}`}
              ukey={row.UKEY}
              status={currentStatus}
              orderType={currentOrderType}
            />
            <DeleteOrderButton
              key={`delete-order-button-${row.UKEY}`}
              ukey={row.UKEY}
              onDeleteOrder={(data) => setState({ data })}
            />
          </>
        )
      default:
        return (
          <>
            {idRol === USER_ROLES.CLIENT &&
              isPossibleChangeDateToSend(row.STATUS) && (
                <ShowOrderModalButton
                  key={`show-order-button-${row.UKEY}`}
                  id={row.ID}
                  onClick={() =>
                    setState({
                      showClientModal: true,
                      rowSelected: row
                    })
                  }
                />
              )}
            <DuplicateOrderButton
              ukey={row.UKEY}
              key={`duplicate-order-button-${row.UKEY}`}
            />
            <DetailOrderButton
              ukey={row.UKEY}
              status={currentStatus}
              key={`detail-order-button-${row.UKEY}`}
            />
          </>
        )
    }
  }
}
