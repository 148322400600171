import queryString from 'query-string'
import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import { formatDate } from '../../core/utils/date'
import { normalizeString } from '../../core/utils/string'
import { useQueryParamsHandler } from '../../hook/useQueryParams.hook'
import Select from './../select/select'
import './table.css'

const TextField = styled.input`
  height: 32px;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }
`

const Filter = ({ onFilter, value }) => (
  <TextField
    id="search"
    type="search"
    role="search"
    placeholder="Buscador"
    value={value}
    onChange={(e) => onFilter(e.target.value)}
  />
)

const Table = (props) => {
  const [filterText, setFilterText] = React.useState('')
  const [filterBy, setFilterBy] = React.useState(props.keyFilter)
  const { changeQueryParam, switchKeyQueryParam, search } =
    useQueryParamsHandler()
  const filteredItems = props.data
    ? props.data.filter((data) => {
        const formatDates = {
          FECHA_CREACION: formatDate,
          DELIVERY_DATE: formatDate,
          FECHA_ENVIO: formatDate
        }
        const item = data[filterBy] ?? ''
        const formater = formatDates[filterBy]
        const value = formater ? formater(item) : item
        return normalizeString(value).includes(normalizeString(filterText))
      })
    : []

  const handleInputChage = (value) => {
    setFilterText(value)
    changeQueryParam(filterBy, value)
  }

  const subHeaderComponentMemo = React.useMemo(
    () => <Filter value={filterText} onFilter={handleInputChage} />,
    [filterText, filterBy]
  )

  const handleSelectChange = (event) => {
    setFilterBy(event.value)
    switchKeyQueryParam(filterBy, event.value)
  }

  const columnSelect = props.columns
    .map((v) => {
      return { value: v.selector, label: v.name }
    })
    .filter((v) => typeof v.value !== 'undefined')

  useEffect(() => {
    const values = queryString.parse(search)
    const options = columnSelect.map((v) => v.value)
    Object.keys(values).forEach((key) => {
      if (options.includes(key)) {
        setFilterBy(key)
        setFilterText(values[key])
      }
    })
  }, [])

  return (
    <div
      className={`datatable-container ${props.inputSearchShort ? 'short' : ''}`}
    >
      <div className="selector-container">
        <div className="extraFields">{props.extraFields}</div>
        <Select
          options={columnSelect}
          value={filterBy}
          hideLabel={true}
          onChange={handleSelectChange}
          notSaveInOrder
          dimensions={{
            label: 0
          }}
          style={{ width: '180px' }}
        />
      </div>
      <DataTable
        title={props.title}
        columns={props.columns}
        data={filteredItems}
        subHeader
        //keyField={`key-table-${props.title}`}
        subHeaderComponent={subHeaderComponentMemo}
        pagination={props.pagination}
        noDataComponent={'No hay datos a mostrar'}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de'
        }}
        defaultSortField={props.defaultSortField}
        defaultSortAsc={props.defaultSortAsc}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
      />
    </div>
  )
}

export default Table
