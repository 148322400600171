const API_URL = process.env.REACT_APP_API_URL || `${window.location}/backoffice`|| 'http://localhost:8081/backoffice';

export const environment = {
  baseUrl: `${API_URL}`,
  root: `${API_URL}`,
  rootFiles: `${API_URL}/api/files`,
  assetFiles: `${API_URL}/assets/docs`,
  urlResourcesZip: `${API_URL}/api/files/zip-order-attachment/`,
  urlResources: `${API_URL}/api/files/order-attachment/`,
  urlDeliveriesResource: `${API_URL}/api/files/order-finished/`,
  urlProductionResource: `${API_URL}/api/files/order-production/`,
  urlInvoicesResources: `${API_URL}/api/files/invoices/`
}
