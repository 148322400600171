import { addMonths } from 'date-fns'
import React, { useEffect } from 'react'
import DatePicker from 'react-multi-date-picker'
import 'react-multi-date-picker/styles/layouts/prime.css'
import { toast } from 'react-toastify'
import { FORMAT_DATE, FORMAT_SERVER_DATE } from '../../../core/constants/constants'
import { parseDate } from '../../../core/utils/date'
import { setNewDeliveryDate } from '../../../resources/services'

export const NewDeliveryOrderButton = ({
  currentDeliveryDate,
  ukey,
  onChange,
  minimumDate,
  disabled = false
}) => {
  const [minDate, setMinDate] = React.useState('')
  const [maxDate, setMaxDate] = React.useState('')
  const [date, setDate] = React.useState('')

  useEffect(() => {
    const currentDelivery = parseDate(currentDeliveryDate)
    const maxDate = addMonths(new Date(currentDelivery), 1)
    minimumDate ? setMinDate(minimumDate) : setMinDate(currentDelivery)
    setMaxDate(parseDate(maxDate))
    setDate(currentDelivery)
  }, [currentDeliveryDate])

  const handlerOnChange = (newDate) => {
    const dateFormat = newDate.format(FORMAT_SERVER_DATE.toUpperCase())
    return (
      window.confirm(
        `Estás seguro de que deseas cambiar la fecha estimada de envio del pedido?`
      ) &&
      setNewDeliveryDate({ id: ukey, newDeliveryDate: dateFormat }).then(
        (response) => {
          if (response.response === 1 && response.update === 1) {
            toast('Se ha modificar el pedido correctamente', {
              type: toast.TYPE.SUCCESS
            })
            setDate(dateFormat)
            onChange && onChange(dateFormat)
          } else {
            toast('No se ha modificar el pedido correctamente', {
              type: toast.TYPE.ERROR
            })
          }
        }
      )
    )
  }

  return (
    <div className="input-group">
      <DatePicker
        value={date}
        minDate={minDate}
        maxDate={maxDate}
        render={<DateInput disabled={disabled} />}
        format={FORMAT_DATE.toUpperCase()}
        onChange={handlerOnChange}
      />
    </div>
  )
}

export const DateInput = ({
  openCalendar,
  value,
  disabled,
  handlerOnChange
}) => {
  return (
    <input
      type={'text'}
      readOnly
      onFocus={() => {
        openCalendar && openCalendar()
      }}
      defaultValue={value}
      onChange={handlerOnChange}
      className={disabled ? 'input-control disabled' : 'input-control'}
      style={{ marginLeft: '1rem', borderRadius: '3px', height: '3rem' }}
    />
  )
}
