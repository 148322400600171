import { toast } from 'react-toastify'
import { ORDER_TYPE } from '../../core/constants/roles'
import { calculatePriceWithIva } from '../../core/utils/price'
import { history } from '../../history'
import { setOrders } from './../../resources/services'
import {
  getCombinationOrderKeys,
  getCombinationOrderObject
} from './orders.bussines'

const calculatePrice = (newData) => {
  const keys = Object.keys(newData)
  const customer = newData.customers.find((v) => v.value === newData.id_cliente)
  let price = newData.materials
    .map((v) => {
      let item = keys.find((n) => newData[n] === v.ID)
      if (item) {
        const position = item.indexOf('_multiple')
        item = position !== -1 ? item.substring(0, position) : item
        if (newData[item + '_pie'] && newData[item + '_pie'] === 'Bi') {
          return parseFloat(v.PRICE * 2)
        }
        return parseFloat(v.PRICE)
      }
      return 0
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue)
  price =
    newData.orderLogoAttachment && newData.orderLogoAttachment.length > 0
      ? price + 3
      : price

  return newData.discount
    ? price - parseFloat((price * newData.discount) / 100)
    : price
}

const calculateFixOrderPrice = (newData) => {
  const price = newData.orderToFixPrice
  const customer = newData.customers?.find(
    (v) => v.value === newData.id_cliente
  )
  return customer
    ? +calculatePriceWithIva(price, +customer?.discount ?? 0)
    : price
}

export const updateOrderAction = (data) => (dispatch, getState) => {
  const { orders, app } = getState()
  // Eliminamos el _selector para aquellos que no lo tengan
  Object.keys(data).forEach((v) => {
    if (v + '_selector' in orders) {
      delete orders[v + '_selector']
    }
  })

  // check if is a combination order.
  const keyToUpdate = Object.keys(data)[0]
  const newData = {
    ...orders,
    ...data,
    ...getCombinationOrderObject(keyToUpdate)
  }

  const calculatePriceByType = {
    [ORDER_TYPE.NEW]: calculatePrice,
    [ORDER_TYPE.FIX]: calculateFixOrderPrice
  }
  const type = newData.order_type ?? ORDER_TYPE.NEW
  const price = calculatePriceByType[type](newData) || calculatePrice(newData)

  // Actualizamos precio
  newData.price = price

  // Id series ( id bbdd )
  newData.id_series = data.id_series

  // Guardamos en BBDD el pedido si está relacionado el Id usuario y nombre del paciente
  const dataToSend = { ...newData, UKEY: localStorage.getItem('ukey') }

  if (
    (dataToSend.id_cliente !== '' || app.idRol !== '99') &&
    dataToSend.nombre_del_paciente &&
    dataToSend.apellidos_del_paciente
  ) {
    setOrders(dataToSend).then((v) => {
      if (v.update !== 1) {
        toast('No se ha podido guardar el pedido', { type: toast.TYPE.ERROR })
      } else {
        newData.id = v.id
        newData.id_series = v.id_series
        let url = window.location.pathname
        url =
          window.location.pathname.charAt(url.length - 1) === '/'
            ? url.substring(0, url.length - 1)
            : url
        if (url.split('/').length <= 3) {
          !url.includes(v.id) && history.push(url + '/' + v.id)
        }
      }

      dispatch({
        type: 'UPDATE_ORDER',
        data: newData
      })
    })
  } else {
    dispatch({
      type: 'UPDATE_ORDER',
      data: newData
    })
  }
}

export const resetStoreAction = () => ({
  type: 'RESET_STORE'
})

export const deleteOrderAction = (data) => (dispatch, getState) => {
  const { orders } = getState()
  const newData = { ...orders }
  const toDelete = typeof data === 'object' ? data : [data]
  toDelete.map((v) => delete newData[v])

  // check if is a combination order.
  console.log(data)
  getCombinationOrderKeys(data).map((v) => delete newData[v])

  const price = calculatePrice(newData)

  // Actualizamos precio
  newData.price = price

  // Guardamos en BBDD el pedido si está relacionado el Id usuario y nombre del paciente
  const dataToSend = { ...newData, UKEY: localStorage.getItem('ukey') }
  if (
    dataToSend.id_cliente !== '' &&
    dataToSend.nombre_del_paciente &&
    dataToSend.apellidos_del_paciente
  ) {
    setOrders(dataToSend).then((v) => {
      if (v.update !== 1) {
        toast('No se ha podido guardar el pedido', { type: toast.TYPE.ERROR })
      } else {
        newData.id = v.id
      }

      dispatch({
        type: 'DELETE_ORDER',
        data: newData
      })
    })
  } else {
    dispatch({
      type: 'DELETE_ORDER',
      data: newData
    })
  }
}
