import React from "react";
import { ChangeDeliveryDateRow } from "./rows/changeDeliveryDate/changeDeliveryDate";
import "../orders.css";

export const ClientModal = ({ show, onClose, onNewDeliveryDate, data }) => {
  const handleOnClose = () => {
    onClose && onClose();
  };

  const handlerOnChangeDate = (newDate) => {
    onNewDeliveryDate && onNewDeliveryDate(newDate);
  };

  const currentDeliveryDate = data.DELIVERY_DATE;
  const deliveryDateModified = data.DELIVERY_DATE_MODIFIED;

  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => handleOnClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Opciones del pedido</h4>
          </div>
          <div className="modal-body">
            <ChangeDeliveryDateRow
              ukey={data.UKEY}
              currentDeliveryDate={currentDeliveryDate}
              onChange={handlerOnChangeDate}
              disabled={deliveryDateModified === "1"}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
