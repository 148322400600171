import InputOption from './components/input-option/input-option'
import Input from './components/input/input'
import Selector from './components/select/select'
import SelectableImage from './components/selectable-image/selectable-image'
import TextArea from './components/text-area/text-area'
import Title from './components/title/title'
import UploadFile from './components/upload-file/upload-file'

import {
  default as completa,
  default as horma_clasica
} from './assets/images/materiales/horma/horma-clasica-y-completa.png'
import horma_estrecha from './assets/images/materiales/horma/horma-estrecha.png'
import horma_pie_plano from './assets/images/materiales/horma/horma-pie-plano.png'
import horma_sandalia from './assets/images/materiales/horma/horma-sandalia-y-sport.png'
import horma_sport from './assets/images/materiales/horma/hormasport.png'
import retrocapital from './assets/images/materiales/horma/largo-retrocapital.png'
import subcapital from './assets/images/materiales/horma/largo-subcapital.png'
import mb_subcapital from './assets/images/materiales/horma/mbl-subcapital.jpg'

import adaptacion_del_arco_100 from './assets/images/materiales/horma/adaptacion_del_arco/adaptacion del arco - adaptado 100.png'
import adaptacion_del_arco_50 from './assets/images/materiales/horma/adaptacion_del_arco/adaptacion del arco - adaptado 50.png'
import adaptacion_del_arco_none from './assets/images/materiales/horma/adaptacion_del_arco/adaptacion del arco - sin adaptado.png'

import carbono_flex from './assets/images/materiales/material-base/carbon-flex.png'
import carbono from './assets/images/materiales/material-base/carbono.jpg'
import dennis from './assets/images/materiales/material-base/dennis.png'
import eva_comby from './assets/images/materiales/material-base/eva-comby.png'
import eva1 from './assets/images/materiales/material-base/eva1.png'
import eva3 from './assets/images/materiales/material-base/eva3.png'
import fibra_vidrio from './assets/images/materiales/material-base/fibra-vidrio.png'
import lelievre from './assets/images/materiales/material-base/lelievre.png'
import plastazote from './assets/images/materiales/material-base/plastazote.png'
import polipropileno from './assets/images/materiales/material-base/pp.png'
import resina from './assets/images/materiales/material-base/resina.png'

// FORROS
import microfibra from './assets/images/materiales/forros/microfibra.png'
import pp from './assets/images/materiales/forros/ppiel-marron.png'

export const customer_selector = {
  title: 'Cliente',
  dimension: 12,
  components: [
    {
      type: Selector,
      properties: {
        dimensions: {
          label: 2,
          element: 4
        },
        options: [{ value: '', label: 'No customer' }],
        placeholder: 'Cliente',
        label: 'Cliente',
        name: 'id_cliente'
      }
    }
  ]
}

export const datos_personales = [
  /*{
        title: 'Datos del profesional',
        dimension: 12,
        components: [
            {
                type: Input,
                properties: {
                    dimensions: {
                        label: 4,
                        element: 8,
                    },
                    required: true,
                    placeholder: 'Nombre',
                    label: 'Nombre de la clinica / profesional',
                    name: 'nombre_profesional_o_clinica',
                }
            }
        ]
    },*/
  {
    title: 'Datos del paciente',
    dimension: 6,
    components: [
      {
        type: Input,
        properties: {
          dimensions: {
            label: 2,
            element: 4
          },
          required: true,
          placeholder: 'Nombre del paciente',
          label: 'Nombre',
          name: 'nombre_del_paciente'
        }
      },
      {
        type: Input,
        properties: {
          dimensions: {
            label: 2,
            element: 4
          },
          placeholder: 'Apellidos del paciente',
          label: 'Apellidos',
          name: 'apellidos_del_paciente'
        }
      },
      {
        type: Input,
        properties: {
          dimensions: {
            label: 2,
            element: 4
          },
          placeholder: 'Número de Calzado',
          label: 'Calzado',
          name: 'calzado_del_paciente',
          type: 'number',
          required: true
        }
      },
      {
        type: Input,
        properties: {
          dimensions: {
            label: 2,
            element: 4
          },
          placeholder: '',
          label: 'NHC',
          name: 'nhc_del_paciente'
        }
      },
      {
        type: Selector,
        properties: {
          dimensions: {
            label: 2,
            element: 4
          },
          options: [
            { value: 'hombre', label: 'Hombre' },
            { value: 'mujer', label: 'Mujer' }
          ],
          placeholder: 'Sexo del paciente',
          label: 'Sexo',
          name: 'sexo_del_paciente'
        }
      }
    ]
  },
  {
    title: 'Tratamiento ortopológico',
    dimension: 6,
    components: [
      {
        type: TextArea,
        name: 'tratamiento_ortopologico',
        properties: {
          dimension: 12,
          placeHolder: 'Tratamiento...',
          style: {
            height: 112,
            width: '100%'
          },
          name: 'tratamiento_ortopologico',
          label: 'Tratamiento ortopológico'
        }
      }
    ]
  },
  {
    title: 'Molde',
    dimension: 6,
    components: [
      {
        type: InputOption,
        properties: {
          dimensions: {
            label: 12,
            element: 12
          },
          type: 'radio',
          group: [
            {
              type: 'radio',
              label: 'Carga',
              value: 'carga',
              name: 'molde'
            },
            {
              type: 'radio',
              label: 'Descarga',
              value: 'descarga',
              name: 'molde'
            },
            {
              type: 'radio',
              label: 'Semicarga',
              value: 'semiCarga',
              name: 'molde'
            },
            {
              type: 'radio',
              label: 'Espuma fenólica',
              value: 'espuma_femolica',
              name: 'molde'
            },
            {
              type: 'radio',
              label: 'Otra',
              value: 'otra',
              name: 'molde'
            }
          ],
          placeholder: 'Opciones',
          label: '¿Como has tomado el molde?',
          name: 'molde',
          addStyle: {
            textAlign: 'left',
            paddingBottom: '10px'
          }
        }
      }
    ]
  },
  {
    title: 'Molde descripción',
    dimension: 6,
    components: [
      {
        type: TextArea,
        name: 'molde_otras',
        properties: {
          dimension: 12,
          placeholder:
            'Escribe como has tomado el molde en caso de no cumplir con las anteriores opciones...',
          style: {
            height: 187,
            clear: 'both'
          },
          name: 'molde_otras',
          label: 'Otras opciones'
        }
      }
    ]
  },
  {
    title: 'Archivos (Escaner, documentos, etc...)',
    dimension: 12,
    components: [
      {
        type: UploadFile,
        properties: {
          dimensions: {
            label: 2,
            element: 10
          },
          required: false,
          formatRequired: [],
          label: 'Archivo escaneado',
          name: 'orderAttachment',
          id: 'uploadFile',
          text: 'Arrastra y suelta aquí el archivo escaneado o otros archivos útiles para el configurador.',
          maxSize: 100000000,
          multiple: true,
          type: 3,
          needUkey: true,
          setOnStore: true
        }
      }
    ]
  }
]

export const horma_material_base = [
  {
    title: 'Horma',
    dimension: 12,
    components: [
      {
        type: SelectableImage,
        properties: {
          name: 'horma',
          value: 'HC',
          dimension: 2,
          label: 'Clásica',
          src: horma_clasica
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'horma',
          value: 'HE',
          dimension: 2,
          label: 'Estrecha',
          src: horma_estrecha
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'horma',
          value: 'HSP',
          dimension: 2,
          label: 'Sport',
          src: horma_sport
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'horma',
          value: 'HS',
          dimension: 2,
          label: 'Sandalia',
          src: horma_sandalia
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'horma',
          value: 'HPP',
          dimension: 2,
          label: 'Pie plano',
          src: horma_pie_plano
        }
      },
      {
        type: InputOption,
        properties: {
          dimensions: {
            label: 4,
            element: 8
          },
          label: 'Adaptación del arco (pronación mediotarsiana)',
          name: 'adaptacion_del_arco',
          style: {
            clear: 'both',
            fontWeight: 'bold',
            backgroundColor: '#f5f9ff',
            paddingTop: '5px',
            height: '30px'
          },
          addStyleLabel: {
            marginTop: '0px'
          }
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'adaptacion_del_arco_type',
          value: 'HAA0',
          dimension: 2,
          label: 'Sin adaptación',
          src: adaptacion_del_arco_none
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'adaptacion_del_arco_type',
          value: 'HAA50',
          dimension: 2,
          label: 'adaptado 50%',
          src: adaptacion_del_arco_50
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'adaptacion_del_arco_type',
          value: 'HAA100',
          dimension: 2,
          label: 'adaptado 100%',
          src: adaptacion_del_arco_100
        }
      },
      {
        type: Title,
        properties: {
          title: 'Longitud Plantilla',
          size: 4
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'horma_longitud',
          value: 'HLC',
          dimension: 2,
          label: 'Completa',
          src: completa
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'horma_longitud',
          value: 'HLS',
          dimension: 2,
          label: 'Subcapital',
          src: subcapital
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'horma_longitud',
          value: 'HLR',
          dimension: 2,
          label: 'Retrocapital',
          src: retrocapital
        }
      },
      {
        type: Title,
        properties: {
          title: 'Longitud Material base',
          size: 4
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'longitud_material_base',
          value: 'HLMBA',
          dimension: 2,
          label: 'Anterocapital',
          src: subcapital
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'longitud_material_base',
          value: 'HLMBS',
          dimension: 2,
          label: 'Subcapital',
          src: mb_subcapital
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'longitud_material_base',
          value: 'HLMBR',
          dimension: 2,
          label: 'Retrocapital',
          src: retrocapital
        }
      }
    ]
  },
  {
    title: 'Material base',
    dimension: 12,
    components: [
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'MBC',
          dimension: 3,
          label: 'Carbono',
          src: carbono
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'MBFV',
          dimension: 3,
          label: 'Fibra de vidrio',
          src: fibra_vidrio
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'material_carbono_flex',
          dimension: 3,
          label: 'Carbon flex',
          src: carbono_flex,
          placeholder: 'Selecciona carbono',
          options: [
            {
              label: '1,5mm',
              value: 'MBCF15'
            },
            {
              label: '2mm',
              value: 'MBCF2'
            }
          ]
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'material_resina',
          dimension: 3,
          label: 'Resinas sidas',
          src: resina,
          placeholder: 'Selecciona resina',
          value: 'MBRS'
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'material_polipropileno',
          dimension: 3,
          label: 'Polipropileno',
          src: polipropileno,
          placeholder: 'Selecciona medida',
          options: [
            {
              label: '2mm',
              value: 'MBP2'
            },
            {
              label: '3mm',
              value: 'MBP3'
            }
          ]
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'MBD',
          dimension: 3,
          label: 'Dennis',
          src: dennis
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'material_E.V.A-densidad-1',
          dimension: 3,
          label: 'E.V.A 1 Densidad ',
          src: eva1,
          options: [
            {
              label: '25ºsh',
              value: 'MBE1D25'
            },
            {
              label: '35ºsh',
              value: 'MBE1D35'
            },
            {
              label: '45ºsh',
              value: 'MBE1D45'
            }
          ]
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'MBEP',
          dimension: 3,
          label: 'Plastazote',
          src: plastazote
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'MBEC',
          dimension: 3,
          label: 'E.V.A comby (30º/45sh)',
          src: eva_comby
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'MBE3',
          dimension: 3,
          label: 'E.V.A 3 Densidades',
          src: eva3
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'material_base',
          value: 'MBLL',
          dimension: 3,
          label: 'Lelievre',
          src: lelievre
        }
      }
      // {
      //   type: SelectableImage,
      //   properties: {
      //     name: 'material_base',
      //     value: 'material_TFI',
      //     dimension: 3,
      //     label: 'TFI',
      //     src: tfi,
      //     options: [
      //       {
      //         label: '1,5mm',
      //         value: 'MBTFI15M'
      //       },
      //       {
      //         label: '2mm',
      //         value: 'MBTFI2M'
      //       }
      //     ]
      //   }
      // }
    ]
  }
]

export const forros = [
  {
    title: 'Forros',
    dimension: 12,
    components: [
      {
        type: Title,
        properties: {
          title: 'Forro superior',
          size: 4
        }
      },
      {
        type: Selector,
        properties: {
          dimensions: {
            label: 2,
            element: 4
          },
          options: [
            { value: 'FSE0', label: 'Sin Eva' },
            { value: 'FSE1', label: '1mm' },
            { value: 'FSE2', label: '2mm' },
            { value: 'FSE3', label: '3mm' },
            { value: 'FSE4', label: '4mm' },
            { value: 'FSE5', label: '5mm' },
            { value: 'FSESLW302', label: 'Lunasoft SLW 30sh 2mm (azul)' },
            { value: 'FSESLW303', label: 'Lunasoft SLW 30sh 3mm (azul)' },
            { value: 'FSESL402', label: 'Lunasoft SL 40sh 2mm (marino)' },
            { value: 'FSESL403', label: 'LuLunasoft SL 40sh 3mm (marino)' },
            { value: 'FSESL253', label: 'Lunalastik 25sh 3mm (negro)' }
          ],
          placeholder: 'Grosor',
          label: 'E.V.A',
          name: 'forro_superior',
          style: {
            marginBottom: 15
          }
        }
      },
      {
        type: Selector,
        properties: {
          clear: true,
          dimensions: {
            label: 2,
            element: 4
          },
          options: [
            { value: '', label: 'Sin grosor' },
            { value: 'FSEE1', label: '1mm' },
            { value: 'FSEE2', label: '2mm' },
            { value: 'FSEE3', label: '3mm' },
            { value: 'FSEE4', label: '4mm' },
            { value: 'FSEE5', label: '5mm' }
          ],
          placeholder: 'Grosor',
          label: 'E.V.A Extra antepié',
          name: 'eva_extra_antepie',
          style: {
            marginBottom: 15
          }
        }
      },
      {
        type: Input,
        properties: {
          clear: true,
          dimensions: {
            label: 2,
            element: 4
          },
          required: true,
          placeholder: 'Selecciona color...',
          label: 'Color',
          name: 'forro_superior_color'
        }
      },
      {
        type: Selector,
        properties: {
          clear: true,
          dimensions: {
            label: 2,
            element: 4
          },
          options: [
            { value: 'FSCET', label: 'Tricolor' },
            { value: 'FSCEV', label: 'Vaca' },
            { value: 'FSCEMIL', label: 'Militar' },
            { value: 'FSCEMAR', label: 'Mármol' },
            { value: 'FSCEMIC', label: 'Microperforado' }
          ],
          placeholder: 'Selecciona color...',
          label: 'Colores especiales',
          name: 'specialColors'
        }
      },
      {
        type: Title,
        properties: {
          title: 'Acabado ( opcional )',
          size: 4
        }
      },
      {
        type: SelectableImage,
        properties: {
          name: 'acabado_forro',
          value: 'polipiel',
          dimension: 2,
          label: 'Polipiel',
          src: pp,
          placeholder: 'Selecciona color',
          options: [
            {
              label: 'Marron chocolate',
              value: 'FAPM'
            },
            {
              label: 'Negro',
              value: 'FAPN'
            }
          ],
          unselectable: true
        }
      },
      {
        type: SelectableImage,
        properties: {
          label: 'Micro fibra A.P',
          value: 'micro_fibra_a.p',
          name: 'acabado_forro',
          dimension: 2,
          src: microfibra,
          placeholder: 'Selecciona color',
          unselectable: true,
          options: [
            {
              label: 'Negro',
              value: 'FAMFAPN'
            },
            {
              label: 'Beige',
              value: 'FAMAPB'
            }
          ]
        }
      },
      // {
      //   type: InputOption,
      //   properties: {
      //     dimensions: {
      //       label: 2,
      //       element: 6
      //     },
      //     label: 'Poron',
      //     name: 'poron',
      //     type: 'checkbox',
      //     group: [
      //       {
      //         type: 'radio',
      //         label: 'Sin poron',
      //         value: 'FAP0',
      //         name: 'poron'
      //       },
      //       {
      //         type: 'radio',
      //         label: 'Ap',
      //         value: 'FAPAP',
      //         name: 'poron'
      //       },
      //       {
      //         type: 'radio',
      //         label: 'Rp',
      //         value: 'FAPRP',
      //         name: 'poron'
      //       },
      //       {
      //         type: 'radio',
      //         label: 'Completo',
      //         value: 'FAPC',
      //         name: 'poron'
      //       }
      //     ]
      //   }
      // },
      // {
      //   type: InputOption,
      //   properties: {
      //     dimensions: {
      //       label: 2,
      //       element: 6
      //     },
      //     label: 'Propulsor Ap',
      //     name: 'propulsorAp',
      //     type: 'checkbox',
      //     group: [
      //       {
      //         type: 'checkbox',
      //         label: 'Propulsor Ap',
      //         value: 'FAPROPAP',
      //         name: 'propulsorAp'
      //       }
      //     ]
      //   }
      // },
      {
        type: Title,
        properties: {
          title: 'Materiales tecnicos',
          size: 4
        }
      },
      {
        type: Selector,
        properties: {
          unselectable: true,
          dimensions: {
            label: 2,
            element: 2
          },
          label: 'Ante pie',
          name: 'materialTecnicoAntePie',
          options: [
            {
              label: 'Poron 1.5mm',
              value: 'FAMTP15'
            },
            {
              label: 'Poron 3mm',
              value: 'FAMTP3'
            },
            {
              label: 'Poron XRD',
              value: 'FAMTPXRD'
            },
            {
              label: 'Dynatenne 2mm',
              value: 'FAMTD2'
            },
            {
              label: 'Astro form 2mm',
              value: 'FAMTAF2'
            }
          ],
          style: {
            marginBottom: 15
          }
        }
      },
      {
        type: Selector,
        properties: {
          unselectable: true,
          dimensions: {
            label: 2,
            element: 2
          },
          label: 'Retro pie',
          name: 'materialTecnicoRetroPie',
          options: [
            {
              label: 'Poron 1.5mm',
              value: 'FAMTP15'
            },
            {
              label: 'Poron 3mm',
              value: 'FAMTP3'
            },
            {
              label: 'Poron XRD',
              value: 'FAMTPXRD'
            },
            {
              label: 'Dynatenne 2mm',
              value: 'FAMTD2'
            },
            {
              label: 'Astro form 2mm',
              value: 'FAMTAF2'
            }
          ],
          style: {
            marginBottom: 15
          }
        }
      },
      {
        type: Selector,
        properties: {
          unselectable: true,
          dimensions: {
            label: 2,
            element: 2
          },
          label: 'completo',
          name: 'materialTecnicoCompleto',
          options: [
            {
              label: 'Poron 1.5mm',
              value: 'FAMTP15'
            },
            {
              label: 'Poron 3mm',
              value: 'FAMTP3'
            },
            {
              label: 'Poron XRD',
              value: 'FAMTPXRD'
            },
            {
              label: 'Dynatenne 2mm',
              value: 'FAMTD2'
            },
            {
              label: 'Astro form 2mm',
              value: 'FAMTAF2'
            }
          ],
          style: {
            marginBottom: 15
          }
        }
      },
      // {
      //   type: Selector,
      //   properties: {
      //     dimensions: {
      //       label: 2,
      //       element: 6
      //     },
      //     label: 'Situación',
      //     name: 'situacion',
      //     options: [
      //       {
      //         label: 'Antepie',
      //         value: 'FAMTSA'
      //       },
      //       {
      //         label: 'Retropie',
      //         value: 'FAMTSR'
      //       },
      //       {
      //         label: 'Completa',
      //         value: 'FAMTSC'
      //       }
      //     ]
      //   }
      // },
      {
        type: Title,
        properties: {
          title: 'Forro inferior',
          size: 4
        }
      },
      {
        type: Selector,
        properties: {
          value: 'FIMFC',
          dimensions: {
            label: 2,
            element: 4
          },
          label: 'Material inferior',
          name: 'materialInterior',
          options: [
            {
              label: 'Fibra de carbono',
              value: 'FIMFC'
            },
            {
              label: 'EVA 1mm Sh60',
              value: 'FIMEVA'
            }
          ],
          style: {
            marginBottom: 15
          }
        }
      },
      {
        type: InputOption,
        properties: {
          dimensions: {
            label: 1,
            element: 4
          },
          hideLabel: true,
          label: 'Tipo material inferior',
          name: 'materialInteriorType',
          group: [
            {
              type: 'radio',
              label: 'Pegada',
              value: 'FIP',
              name: 'materialInteriorType'
            },
            {
              type: 'radio',
              label: 'Suelta',
              value: 'FIS',
              name: 'materialInteriorType'
            }
          ]
        }
      },
      {
        type: InputOption,
        properties: {
          dimensions: {
            label: 4,
            element: 8
          },
          label: 'Forro inferior completo',
          name: 'forro_inferior_completo',
          type: 'checkbox',
          group: [
            {
              type: 'checkbox',
              label: '',
              value: 'FIC',
              name: 'forro_inferior_completo'
            }
          ],
          style: {
            clear: 'both',
            fontWeight: 'bold',
            backgroundColor: '#f5f9ff',
            paddingTop: '5px',
            height: '30px'
          },
          addStyleLabel: {
            marginTop: '0px'
          }
        }
      }
    ]
  },
  {
    title: 'Logo personalizado ',
    dimension: 12,
    components: [
      {
        type: InputOption,
        properties: {
          dimensions: {
            label: 2,
            element: 10
          },
          label: 'Logo de la empresa',
          name: 'logo_empresa',
          type: 'checkbox',
          group: [
            {
              type: 'checkbox',
              label: '',
              value: 'LE',
              name: 'logo_empresa'
            }
          ],
          style: {
            clear: 'both',
            fontWeight: 'bold',
            // backgroundColor: '#f5f9ff',
            paddingTop: '5px',
            paddingBottom: '20px',
            height: '30px'
          },
          addStyleLabel: {
            marginTop: '0px'
          }
        }
      },
      {
        type: UploadFile,
        properties: {
          dimensions: {
            label: 2,
            element: 10
          },
          required: false,
          formatRequired: ['image/*'],
          label: 'Logo personalizado',
          name: 'orderLogoAttachment',
          id: 'uploadFile',
          text: 'Arrastra aquí el logo que se imprimirá en tu plantilla',
          maxSize: 4000000,
          multiple: false,
          type: 4,
          needUkey: true,
          setOnStore: true
        }
      }
    ]
  }
]
