import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Select from '../../../../components/select/select'
import TextArea from '../../../../components/text-area/text-area'
import { ORDER_STATUS } from '../../../../core/constants/roles'
import { CardArregloComponent } from '../components/card-arreglo'
import { DetailArreglo } from '../components/detail-arreglo'
import { NewOrderForArreglo } from '../components/new-order-for-arreglo'
import { ARREGLO_WITHOUT_ORDER } from '../utils'

export const ClientView = ({
  options,
  values,
  status,
  ukey,
  idUser,
  onSelectChange,
  onDescriptionChange,
  onChangeNewOrderForArreglo,
  onSubmit
}) => {
  const history = useHistory()
  const handleOnSubmit = () => {
    onSubmit &&
      onSubmit().then((ukey) => {
        history.push('/pedido/' + ukey)
      })
  }

  return (
    <>
      <CardArregloComponent
        className="col-md-12 col-lg-6"
        title={'Selecciona el pedido para realizar el arreglo'}
      >
        <Select
          options={options}
          virtualized={true}
          value={values.orderSelected.ukey}
          style={{ height: '112px', width: '100%' }}
          hideLabel={true}
          onChange={onSelectChange}
          notSaveInOrder
          isDisabled={status !== ORDER_STATUS.PROPOSAL || ukey !== null}
        />
        {values.orderSelected.ukey && (
          <>
            {values.orderSelected.ukey !== ARREGLO_WITHOUT_ORDER ? (
              <DetailArreglo order={values.orderSelected} />
            ) : (
              <NewOrderForArreglo
                onChange={onChangeNewOrderForArreglo}
                idUser={idUser}
              />
            )}
          </>
        )}
      </CardArregloComponent>
      <CardArregloComponent
        className="col-md-12 col-lg-12"
        title={'Detalle de el arreglo'}
      >
        <TextArea
          disabled={
            status !== ORDER_STATUS.PROPOSAL && status !== ORDER_STATUS.DESIGN
          }
          defaultValue={values.description}
          style={{ height: '112px', width: '100%' }}
          isolateComponent
          onChange={onDescriptionChange}
        />
      </CardArregloComponent>
      {(status === ORDER_STATUS.PROPOSAL || status === ORDER_STATUS.DESIGN) && (
        <div className="col-md-12 col-lg-12">
          <button
            type="button"
            className="btn btn-green pull-right"
            onClick={handleOnSubmit}
            disabled={status === ORDER_STATUS.FABRICATION}
            style={{ marginTop: '15px', marginRight: '15px' }}
          >
            Guardar <i className="entypo-check"></i>
          </button>
        </div>
      )}
    </>
  )
}
