import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Input from "../../components/input/input";
import Table from "../../components/table/table";
import { environment } from "../../deploy/environment";
import {
  changeStatus,
  generateDeliveryResource,
  getDeliveries,
} from "./../../resources/services";

import { FORMAT_DATE } from "../../core/constants/constants";
import { formatDate, formatServerDate } from "../../core/utils/date";
import { calculatePriceWithIva } from "../../core/utils/price";
import { withQueryParamsHandler } from "../../hook/useQueryParams.hook";
import { DetailFinalizadosPedidosModal } from "./components/detail-finalizados-pedidos-modal";
import "./finalizados.css";

class Finalizados extends Component {
  constructor(props) {
    super(props);
    const params = this.props.queryParamsHandler.searchObj;
    const date = params?.date ? params.date : formatDate(new Date());
    this.state = {
      loadError: false,
      data: [],
      dataOrders: [],
      showModal: false,
      currentRow: {},
      name: "",
      price: "",
      showPriceError: false,
      loading: false,
      date: date,
    };

    this.setStatus = this.setStatus.bind(this);
    this.setAllStatus = this.setAllStatus.bind(this);
    this.setDeliveryStatus = this.setDeliveryStatus.bind(this);
    this.getDeliveriesByDate = this.getDeliveriesByDate.bind(this);
    this.openModal = this.openModal.bind(this);

    this.deliveryColumns = [
      {
        name: "Nº Envio",
        selector: "PUBLIC_ID",
        sortable: true,
      },
      {
        name: "Cliente",
        selector: "NAME",
        sortable: true,
      },
      {
        name: "Nombre clínica",
        selector: "CLINIC_NAME",
        sortable: true,
      },
      {
        name: "Email",
        selector: "EMAIL",
        sortable: true,
      },
      {
        name: "Numero de pedidos",
        selector: "NUM_ORDERS",
        sortable: true,
      },
      {
        name: "Fecha de envío",
        selector: "DELIVERY_DATE",
        cell: (row) => {
          return formatDate(row.DELIVERY_DATE);
        },
        sortable: true,
      },
      {
        name: "Precio",
        selector: "SUM_PRICE",
        sortable: true,
        cell: (row) => {
          return `${calculatePriceWithIva(row.SUM_PRICE, row.PRICE_DISCOUNT)}€`;
        },
      },
      {
        name: "Acciones",
        cell: (row) => {
          return [
            <button
              className="btn btn-default"
              style={{ marginLeft: 15 }}
              onClick={() => this.openModal(row.UKEY)}
              key={`${row.PUBLIC_ID}_modal}`}
            >
              <i className="entypo-eye"></i>
            </button>,
            <button
              className="btn btn-default"
              style={{ marginLeft: 15 }}
              key={`${row.PUBLIC_ID}_send}`}
              onClick={() =>
                window.confirm(`Seguro que deseas enviar todos los pedidos?`) &&
                this.setAllStatus(row.UKEY, 7)
              }
            >
              <i className="entypo-right"></i>
            </button>,
            <button
              className="btn btn-default"
              style={{ marginLeft: 15 }}
              onClick={() =>
                window.confirm(
                  `Seguro que deseas entegar en mano todo los pedidos?`
                ) && this.setAllStatus(row.UKEY, 8)
              }
            >
              <i className="entypo-bag"></i>
            </button>,
          ];
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
  }

  setStatus = (id, status) => {
    changeStatus({ id, status }).then((response) => {
      if (response.response === 1 && response.update === 1) {
        const data = this.state.data.filter((v) => v.UKEY !== id);
        let newState = { data };
        this.setState({ ...newState, showModal1: false });
        toast(
          `El pedido se ha ${
            status === 7 ? "enviado" : "entregado"
          } correctamente al cliente`,
          { type: toast.TYPE.SUCCESS }
        );
      } else {
        toast("No se ha podido actualizar el status del pedido", {
          type: toast.TYPE.ERROR,
        });
      }
    });
  };

  setAllStatus = (deliveryId, status) => {
    changeStatus({ deliveryId, status, deliveryStatus: 1 }).then((response) => {
      if (response.response === 1 && response.update === 1) {
        const data = this.state.data.filter((v) => v.UKEY !== deliveryId);
        let newState = { data };
        this.setState({ ...newState, showModal1: false });
        toast(
          `Los pedidos se ha ${
            status === 7 ? "enviado" : "entregado"
          } correctamente al cliente`,
          { type: toast.TYPE.SUCCESS }
        );
      } else {
        toast("No se ha podido actualizar el status del pedido", {
          type: toast.TYPE.ERROR,
        });
      }
    });
  };

  setDeliveryStatus = (id) => {
    changeStatus({ id, status: 7 }).then((response) => {
      if (response.response === 1 && response.update === 1) {
        const data = this.state.data.filter((v) => v.UKEY !== id);
        let newState = { data };
        this.setState({ ...newState, showModal1: false });
        toast("El pedido se ha enviado correctamente al cliente", {
          type: toast.TYPE.SUCCESS,
        });
      } else {
        toast("No se ha podido actualizar el status del pedido", {
          type: toast.TYPE.ERROR,
        });
      }
    });
  };

  openModal = (ukey) => {
    const orders = this.state.data?.find(
      (delivery) => delivery.UKEY === ukey
    )?.orders;
    this.setState({ dataOrders: orders, showModal: true });
  };

  getDeliveriesByDate = (date) => {
    this.setState({ loading: true, date: date });
    this.props.queryParamsHandler.changeQueryParam("date", date);
    getDeliveries(null, 1, null, formatServerDate(date)).then((result) => {
      if (result.response) {
        getDeliveries(null, 0, null, formatServerDate(date)).then((result1) => {
          if (result1.response) {
            this.setState({
              data: [...result.deliveries, ...result1.deliveries],
              loading: false,
            });
          } else {
            toast("Se ha producido un error al realizar la consulta", {
              type: toast.TYPE.ERROR,
            });
          }
        });
      } else {
        toast("Se ha producido un error al realizar la consulta", {
          type: toast.TYPE.ERROR,
        });
      }
    });
  };

  componentDidMount() {
    this.getDeliveriesByDate(this.state.date);
  }
  render() {
    return (
      <Fragment>
        <div className="row-pdf">
          <button
            className="btn btn-default"
            onClick={() => {
              const date = formatServerDate(this.state.date);
              generateDeliveryResource(date).then((val) => {
                const urlResources = `${environment.urlDeliveriesResource}${date}/orders-finished.pdf`;
                window.open(urlResources, "_blank");
              });
            }}
          >
            Exportar PDF
          </button>
        </div>

        <Table
          keyFilter={"DELIVERY_DATE"}
          data={this.state.data}
          columns={this.deliveryColumns}
          title="Finalizados"
          extraFields={
            <Input
              hideLabel
              id="inputDate"
              dimensions={{
                label: 0,
                element: 12,
              }}
              defaultValue={this.state.date}
              onEnter={(value) => this.getDeliveriesByDate(value)}
              placeholder={FORMAT_DATE.toUpperCase()}
              isolateComponent
            />
          }
          pagination
        />

        <DetailFinalizadosPedidosModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          data={this.state.dataOrders}
          onSendOrderInHand={(id, status) => this.setStatus(id, status)}
          onSendOrder={(id, status) => this.setStatus(id, status)}
        ></DetailFinalizadosPedidosModal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps)(withQueryParamsHandler(Finalizados));
