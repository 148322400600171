import { format, parse } from 'date-fns';
import {
  FORMAT_DATE,
  FORMAT_SERVER_DATE,
  FORMAT_TIME
} from '../constants/constants';

export const formatDate = (date) => {
  try {
    return format(new Date(date), FORMAT_DATE)
  } catch(e) {
    return date;
  }
}

export const formatServerDate = (date) => {
  const newFormat = parse(date, FORMAT_DATE, new Date())
  return date ? format(newFormat, FORMAT_SERVER_DATE) : date
}

export const parseDate = (date) => {
  return date ? parse(date, FORMAT_DATE, new Date()) : date
}

export const formatTime = (date) => {
  return date ? format(new Date(date), FORMAT_TIME) : date
}

export const formatDateToUnix = (date) => {
  return date ? new Date(date).getTime() : date
}
